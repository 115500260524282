<template>
  <div class="flex" style="height: 100vh">
    <div
      class="relative block md:flex md:flex-col w-full md:w-2/3 center justify-center bg-jevi-dark overflow-y-auto"
    >
      <div class="md:hidden">
        <router-link to="/inicio">
          <div class="w-1/5 my-4 mx-auto object-scale-down">
            <img src="/static/lif-logo.png" />
          </div>
        </router-link>
      </div>
      <div class="relative w-5/6 md:w-2/3 lg:w-7/12 mx-auto">
        <div class="relative w-full rounded-3xl p-8 bg-white shadow-md">
          <div class="flex flex-col justify-center">
            <h1
              class="text-2xl text-center md:text-left md:text-5xl text-primary-900"
            >
              Inicia sesión
            </h1>
            <div class="flex items-center mt-2">
              <p class="font-light mr-2">¿Aún no te has registrado?</p>
              <t-button variant="link" to="/registro" :disabled="isLoading"
                >Regístrate</t-button
              >
            </div>

            <div class="mt-8">
              <t-input-group
                :feedback="
                  showValidation && errors.first('email')
                    ? errors.first('email')
                    : ''
                "
                :variant="
                  showValidation && errors.first('email') ? 'danger' : ''
                "
                class="mb-2"
              >
                <t-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  classes="mb-4"
                  v-model="user.email"
                  name="email"
                  placeholder="Correo electrónico o usuario"
                  autocomplete="off"
                  :disabled="isLoading"
                  :variant="
                    showValidation && errors.first('email') ? 'danger' : ''
                  "
                />
              </t-input-group>

              <t-input-group
                :feedback="
                  showValidation && errors.first('password')
                    ? errors.first('password')
                    : ''
                "
                :variant="
                  showValidation && errors.first('password') ? 'danger' : ''
                "
                class="mb-2"
              >
                <t-input
                  v-on:keyup.enter="login"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  classes="mb-4"
                  v-model="user.password"
                  name="password"
                  placeholder="Contraseña"
                  autocomplete="off"
                  type="password"
                  ref="password"
                  :disabled="isLoading"
                  :variant="
                    showValidation && errors.first('password') ? 'danger' : ''
                  "
                />
              </t-input-group>

              <t-button
                class="ml-auto"
                variant="link"
                to="/reestablecer"
                :disabled="isLoading"
                >¿Olvidaste la contraseña?</t-button
              >
              <t-button
                class="mt-3 w-full"
                @click="login"
                :disabled="isLoading"
              >
                Iniciar sesión
                <t-loader v-if="isLoading" extraClass="ml-2" />
              </t-button>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:block w-full bg-jevi-dark pb-4 absolute bottom-0">
        <p class="text-white text-center flex justify-center">
          Desarrollado por
          <a href="https://ticktaps.com" class="ml-1" style="color: #cc162e"
            ><strong>Ticktaps</strong></a
          >
        </p>
      </div>
      <div class="block md:hidden w-full bg-jevi-dark mt-1">
        <p class="text-white text-center flex justify-center">
          Desarrollado por
          <a href="https://ticktaps.com" class="ml-1" style="color: #cc162e"
            ><strong>Ticktaps</strong></a
          >
        </p>
      </div>
    </div>
    <div
      class="flex flex-col justify-around hidden md:block md:w-1/3 bg-cream"
      style="height: 100%"
    >
      <div class="w-full h-1/3" />
      <div class="w-full h-1/3">
        <img
          src="/static/lif-logo.png"
          class="w-1/2 mx-auto my-auto"
          alt="Logo LIF"
        />
      </div>
      <div class="w-full h-1/3 flex justify-center items-end pb-4">
        <img
          src="/static/ticktaps-logo.png"
          class="w-1/3"
          alt="Logo Ticktaps"
        />
      </div>
    </div>
  </div>
</template>
<script>
const notify = () => import("@/utils/notify.js")
export default {
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      showValidation: false,
      isLoading: false
    }
  },
  methods: {
    login() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.isLoading = true
          this.$store
            .dispatch("auth/login", {
              user: this.user,
              aclChangeRole: this.$acl.change
            })
            .then((response) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                if (response.message.includes("active")) {
                  notification("login", 402, this.$snotify)
                } else {
                  notification("login", response.code, this.$snotify)
                }
              })
            })
            .catch((error) => {
              this.isLoading = false
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify)
              })
            })
        }
      })
    }
  }
}
</script>
